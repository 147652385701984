import { useEffect, useState } from "react";

import db from "../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

export function useCollection(refName) {
  const [mainData, setMainData] = useState([]);
  const getData = async () => {
    try {
      // collection refrance
      const newsCollectionRe = collection(db, refName);
      // docs in collection
      const docsList = (await getDocs(newsCollectionRe)).docs;
      // get docs data and docs id
      const data = docsList.map((item) => {
        return { id: item.id, ...item.data() };
      });
      // pass data to state to render page
      setMainData(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  // funtion invoic after page load
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [mainData];
}
