import callImage from "../../assets/images/Call.svg";
import whatsAppImage from "../../assets/images/whatsApp.svg";
export const ContactsData = [
  {
    name: "جوال",
    number: "01016463616",
    img: callImage,
    link: "tel:00201016463616",
  },
  {
    name: "واتسأب",
    number: "01016463616",
    img: whatsAppImage,
    link: "https://wa.link/p0b0gy",
  },
];
