import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TabletAndroidTwoToneIcon from "@mui/icons-material/TabletAndroidTwoTone";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import LaptopTwoToneIcon from "@mui/icons-material/LaptopTwoTone";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Slide from "@mui/material/Slide";
import { IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import styles from "./LiveDemo.module.css";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LiveDemoDialog({ open, close, data, displaySkelton }) {
  // frame dimantions -----------------------------------------
  const [frameDimensions, setFrameDimensions] = useState({
    width: "100%",
    height: "100%",
  });

  const DimentionButton = {
    background: "#545454",
    color: "#999",
    transition: "background 0.3s ease",
    border: "none",
    "&:hover": {
      background: "#545454",
      color: "#fff",
    },
  };
  const activeDimentionButton = {
    color: "#FB5B0F",
    backgroundColor: "#FEEAE0",
    border: "none",
    "&:hover": {
      color: "#FB5B0F",
      backgroundColor: "#FEEAE0",
    },
  };

  // close handeler
  const closeHandeler = () => {
    close();

    // reset frame dimantion
    setFrameDimensions({
      width: "100%",
      height: "100%",
    });
  };

  return (
    <div>
      <Dialog
        sx={{ padding: "0px important" }}
        open={open}
        onClose={closeHandeler}
        fullScreen
        className="fullScreenDialog"
        TransitionComponent={Transition}
      >
        {/* Close bar ----- */}
        <Box sx={{ background: "#131921", p: "4px 15px", direction: "ltr" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* close buttons */}
            <IconButton
              onClick={closeHandeler}
              sx={{
                border: "0px",
                background: "#545454",
                color: "#fff",
                borderRadius: "100%",
                width: "34px",
                height: "34px",
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>

            {/* divice preview buttons */}
            <Box sx={{ position: "relative" }}>
              <KeyboardBackspaceIcon className="animatedHand" />

              <Stack
                direction="row"
                justifyItems="center"
                spacing={1}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <IconButton
                  onClick={() => setFrameDimensions({ width: "100%" })}
                  sx={
                    frameDimensions.width === "100%"
                      ? {
                          ...activeDimentionButton,
                        }
                      : {
                          ...DimentionButton,
                        }
                  }
                >
                  <LaptopTwoToneIcon />
                </IconButton>

                <IconButton
                  onClick={() => setFrameDimensions({ width: "810px" })}
                  sx={
                    frameDimensions.width === "810px"
                      ? {
                          ...activeDimentionButton,
                        }
                      : {
                          ...DimentionButton,
                        }
                  }
                >
                  <TabletAndroidTwoToneIcon />
                </IconButton>
                <IconButton
                  onClick={() => setFrameDimensions({ width: "380px" })}
                  sx={
                    frameDimensions.width === "380px"
                      ? {
                          ...activeDimentionButton,
                        }
                      : {
                          ...DimentionButton,
                        }
                  }
                >
                  <PhoneIphoneTwoToneIcon />
                </IconButton>
              </Stack>
            </Box>

            {/* viewsCounter */}
            <Stack direction="row" alignItems="center" spacing="8px">
              <RemoveRedEyeIcon sx={{ fontSize: "18px", color: "#999" }} />
              <Typography variant="body1" color="#999">
                {data.viewsCounter}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        {/* Frame Container ------------- */}
        <div
          style={{
            padding: frameDimensions.width === "100%" ? "0px" : "20px 0px",
          }}
          className={styles.frameContainer}
        >
          <Box
            width={frameDimensions.width}
            height={frameDimensions.height}
            sx={{
              boxShadow: "0px 4px 20px rgba(0,0,0,10%)",
              background: "#fff",
              transition: "width 0.2s ease-in",
              maxWidth: "100%",
              position: "relative",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{
                display: displaySkelton ? "block" : "none",
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: "4",
              }}
            />
            <iframe
              title="liveDemoFrame"
              src={data.frameUrl}
              height="100%"
              width="100%"
              style={{
                border: "0px",
                opacity: displaySkelton ? "0" : "1",
                transition: "opacity 0.3s ease",
              }}
            ></iframe>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}
