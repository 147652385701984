import React from "react";
import { Box } from "@mui/material";
import style from "./CoverCard.module.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function CoverCard({ cardType, data, clickAction }) {
  return (
    <Box
      className={style.CoverCardContainer}
      sx={{
        backgroundColor: data.coverColor,
        height: cardType === "mobileAppCover" ? "430px" : "452px",
      }}
      onClick={clickAction}
    >
      <Box className={style.centerBox}>
        {/*/Mobile cover Box -----------------------*/}
        {cardType === "mobileAppCover" && (
          <Box className={style.mobileCoverBox}>
            <div>
              <LazyLoadImage src={data.cover} alt={data.name} effect="blur" />
            </div>
          </Box>
        )}
        {/*/website Cover Box -----------------------*/}
        {cardType === "websiteCover" && (
          <Box className={style.websiteCoverBox}>
            <LazyLoadImage src={data.cover} alt={data.name} effect="blur" />
          </Box>
        )}
      </Box>

      {/*Eye icon 
      <IconButton className={style.eyeIcon}>
        <RemoveRedEyeIcon />
      </IconButton>
      */}
    </Box>
  );
}

export default CoverCard;
