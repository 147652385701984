import React from "react";
import { Stack, Box, Button, Typography } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import style from "./PersonalBox.module.css";
import logo from "../../../assets/images/logo.svg";
import CloudAnimation from "../../CloudAnimation/CloudAnimation";
import { motion } from "framer-motion";
import { useCollection } from "../../../CustomHooks/UseCollection";

function PersonalBox() {
  // Personal Data from firebase
  const [PersonalData] = useCollection("Personal");
  const { resume } = PersonalData.length > 0 && PersonalData[0];

  return (
    <div className={style.PersonalBoxContainer}>
      <Stack direction="column" spacing="67px">
        <Box>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              duration: 0.6,
              type: "spring",
              stiffness: 150,
            }}
          >
            <img src={logo} alt="sror" />
          </motion.div>
        </Box>
        <article>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.4,
              duration: 0.6,
            }}
          >
            <Typography variant="h3" sx={{ fontSize: "1.75rem" }}>
              ياهلا ,
            </Typography>
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.6,
              duration: 0.6,
            }}
          >
            <Typography variant="body1" mt="25px" color="#333">
              انا سرور رشاد 31 سنه مصري متخصص في مجال تصميم تطبيقات الجـوال
              ومواقع الإنترنت و تكويد واجهات مواقع الإنترنت
            </Typography>
          </motion.div>
        </article>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.7,
            duration: 0.7,
          }}
        >
          <Button
            component="a"
            href={resume}
            target="_blank"
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<SaveAltIcon />}
          >
            تحميل السيرة الذاتية
          </Button>
        </motion.div>
      </Stack>
      {/*------------Cloudes---------------*/}
      <CloudAnimation Duration="30s" Delay_1="12s" Delay_2="0s" Delay_3="3s" />
    </div>
  );
}

export default PersonalBox;
