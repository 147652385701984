import React from "react";
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import style from "./SkillsSection.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { OwlOptions, SkillsCarousel } from "../OwlOptions/OwlOptions";
import { useCollection } from "../../CustomHooks/UseCollection";

function SkillsSection() {
  //  Skills Technologies Data from  firebase -----------------------
  const [SkillsTechnologies] = useCollection("SkillsTechnologies");
  // items in owl-Carousel ------------------------------------
  const SkillsCards = SkillsTechnologies.map((item) => {
    return (
      <Box key={item.id} className={style.skillsBoxItem}>
        <Stack direction="column" spacing="16px" textAlign="center">
          <LazyLoadImage src={item.icon} alt={item.name} effect="blur" />
          <Typography variant="body2">{item.name}</Typography>
        </Stack>
        {item.level && (
          <span className={style.skillsLevel}>
            <Typography variant="body2">{item.level}</Typography>
          </span>
        )}
      </Box>
    );
  });
  //  Skills Counter Data from  firebase -----------------------
  const [SkillsCounter] = useCollection("SkillsCounter");
  const { years, websites, mobileApps, frontEnd } =
    SkillsCounter.length > 0 && SkillsCounter[0];

  return (
    <div className={style.skillsSectionConatiner}>
      <section>
        <Container fixed>
          <div className={style.skillsConatiner}>
            <Grid
              container
              columnSpacing="80px"
              rowSpacing="20px"
              alignItems="center"
            >
              <Grid item xs={12} lg={7}>
                {/*/side Title ------------------*/}
                <div className={style.skillsTitle}>
                  <Stack direction="row" spacing="4px">
                    <Typography variant="h2" sx={{ fontWeight: "400" }}>
                      مهاراتي
                    </Typography>
                    <Typography variant="h2">وخبرات العمل</Typography>
                  </Stack>
                </div>

                {/*--------Section Carousel --------------------*/}
                {SkillsTechnologies.length > 0 && (
                  <ReactOwlCarousel
                    className="owl-theme"
                    {...OwlOptions}
                    {...SkillsCarousel}
                    style={{ position: "initial" }}
                  >
                    {/*items in Carousel */}
                    {SkillsCards}
                  </ReactOwlCarousel>
                )}

                {/*skileton */}
                {SkillsTechnologies.length === 0 && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="113px"
                    sx={{ marginTop: "30px", borderRadius: "10px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={5}>
                <div className={style.skCounterContainer}>
                  {/*item ---------------  */}
                  <div className={style.skCounterItem}>
                    <Typography variant="h3" color="initial">
                      {years}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      سنوات من الخبرة
                    </Typography>
                  </div>

                  {/*item ---------------  */}
                  <div className={style.skCounterItem}>
                    <Typography variant="h3" color="initial">
                      {mobileApps}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      تصميم تطبيق جوال
                    </Typography>
                  </div>

                  {/*item ---------------  */}
                  <div className={style.skCounterItem}>
                    <Typography variant="h3" color="initial">
                      {websites}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      تصميم موقع إلكترونى
                    </Typography>
                  </div>

                  {/*item ---------------  */}
                  <div className={style.skCounterItem}>
                    <Typography variant="h3" color="initial">
                      {frontEnd}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      تكويد موقع إلكتروني
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default SkillsSection;
