import React, { Fragment, useState } from "react";

import { Box, Container, Grid, Skeleton } from "@mui/material";
import PageDescription from "../Components/PageDescription/PageDescription";
import CoverCard from "../Components/Cards/CoverCard/CoverCard";

// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { CoverWebsites, OwlOptions } from "../Components/OwlOptions/OwlOptions";
import IconCard from "../Components/Cards/IconCard/IconCard";
import TotalResultsBar from "../Components/TotalResultsBar/TotalResultsBar";
import ImageCard from "../Components/Cards/ImageCard/ImageCard";

import PreviewDialog from "../Components/PreviewDialog/PreviewDialog";
import { doc, updateDoc } from "firebase/firestore";
import db from "../firebase/firebase";

import { motion } from "framer-motion";
import { useCollection } from "../CustomHooks/UseCollection";
function Web() {
  //  WebsitesData Data from  firebase -----------------------
  const [WebsitesData] = useCollection("Websites");
  const totalResults = WebsitesData.length > 0 && WebsitesData.length;

  const coverCardsList = WebsitesData.map((item) => {
    return (
      <CoverCard
        key={item.id}
        cardType="websiteCover"
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // icons cards ------------------------------------
  const iconCardsList = WebsitesData.map((item) => {
    return (
      <Grid key={item.id} item xs={6} sm={4} lg={2}>
        <IconCard
          cardType="web"
          data={item}
          clickAction={() => previewDialogHandeler(item)}
        />
      </Grid>
    );
  });

  // image Cards List  ------------------------------------
  const imageCardsList = WebsitesData.map((item) => {
    return (
      <ImageCard
        key={item.id}
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // Preview dialog data --------------------------------------
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [previewDialogData, setPreviewDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    poster: "",
    cover: "",
    coverColor: "",
    icon: "",
    date: "",
    viewsCounter: 0,
    preview_video: "",
    previewImages: ["", ""],
    prototype: "",
  });

  // Preview Dialog handeler -----
  const previewDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "WebsitesData", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  return (
    <Fragment>
      {/*Start page description Section ---------------------*/}
      <PageDescription
        topText="اعمالى"
        pageName="تصميم مواقع الإنترنت"
        Description="اقدم الخدمة طبقاً لقواعد منظمة من دارسة متطلبات السوق للخدمة المقدمة من خلال الموقع مرورا بتجربة المستخدم من بداية الدخول على الموقع حتى طلب الخدمة
        "
      />

      {/*Start Covers Section --------------------------------*/}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Container fixed>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              duration: 0.6,
              type: "spring",
              stiffness: 150,
            }}
          >
            <div className="coverCarouselContainer">
              {WebsitesData.length > 0 && (
                <ReactOwlCarousel
                  className="owl-theme"
                  {...OwlOptions}
                  {...CoverWebsites}
                  style={{ position: "initial" }}
                >
                  {/*items in Carousel */}
                  {coverCardsList}
                </ReactOwlCarousel>
              )}
            </div>
          </motion.div>

          {/*Skeleton -------------------------- */}
          {WebsitesData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="400px"
              sx={{ borderRadius: "10px", backgroundColor: "#fff" }}
            />
          )}
        </Container>
      </Box>

      {/*Start icons Cards Section ----------------------------*/}
      <Box sx={{ padding: "46px 0px", display: { xs: "none", sm: "block" } }}>
        <Container fixed>
          <Grid container spacing="16px">
            {iconCardsList}
          </Grid>

          {/*Skeleton -------------------------- */}
          {WebsitesData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="180px"
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Container>
      </Box>

      {/*Start image Cards for mobile view Section ------------*/}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Container fixed>
          {/*Total bar ------*/}
          <TotalResultsBar totalResults={totalResults || 0} />
          {imageCardsList}

          {/*Skeleton -------------------------- */}
          {WebsitesData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="180px"
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Container>
      </Box>

      {/*Prview Dialog ----------------------------*/}
      <PreviewDialog
        open={openPreviewDialog}
        close={() => setOpenPreviewDialog(!openPreviewDialog)}
        dialogData={previewDialogData}
      />
    </Fragment>
  );
}

export default Web;
