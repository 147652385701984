import * as React from "react";
// Styles -------------------------------------------------------
import "../src/Theme/globals.css";
import "../src/Theme/fonts.css";
import "react-lazy-load-image-component/src/effects/blur.css";
// Direction And Theme -------------------------------------------
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { cacheRtl, theme, direction } from "./Theme/Theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Theme/ScrollTop";
// owl.carousel -------------------------------------------------------
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// Components --------------------------------------
import MainNav from "./Components/RoutingComponents/Nav/MainNav";
import BottomMenu from "./Components/RoutingComponents/BottomMenu/BottomMenu";
// Pages -------------------------------------------
import Home from "./Pages/Home";
import Web from "./Pages/Web";
import MobileApps from "./Pages/MobileApps";
import FrontEnd from "./Pages/FrontEnd";
import Contacts from "./Pages/Contacts";
import Footer from "./Components/Footer/Footer";
import AudioEvaluation from "./Components/AudioEvaluation/AudioEvaluation";

function App() {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div dir={direction}>
          <BrowserRouter>
            <ScrollToTop>
              <MainNav />
              <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="/mobileApps" element={<MobileApps />} />
                <Route path="/websites" element={<Web />} />
                <Route path="/frontEnd" element={<FrontEnd />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/*" element={<Home />} />
              </Routes>
              <AudioEvaluation />
              <Footer />
              <BottomMenu />
            </ScrollToTop>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
