import { ButtonBase, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function PosterCard({ data, clickAction }) {
  const posterCardStyle = {
    background: "#E8EAF6",
    borderRadius: "6px",
    height: "205px",
    width: "100%",
    overflow: "hidden",
    textAlign: "left",
    display: "block",
    padding: "0px 23px 0px 25px",
    transition: "background 0.3s ease",
    "&:hover": {
      background: "#E0E2F1",
    },
  };
  return (
    <div>
      <ButtonBase onClick={clickAction} sx={{ ...posterCardStyle }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <article>
            <Typography variant="h5" color="initial" mb="3px">
              {data.name}
            </Typography>

            <Typography variant="body2" color="initial">
              {data.description.slice(0, 41)}
              {data.description.length > 41 && (
                <span style={{ paddingRight: "2px" }}>...</span>
              )}
            </Typography>
          </article>
          <Box
            sx={{
              width: "200px",
              height: "189px",
              borderTopRightRadius: "6px",
              borderTopLeftRadius: "6px",
              background: "#fff",
              overflow: "hidden",
              display: "block",
              mt: "16px",
              boxShadow: "0px -2px 23px rgba(0,0,0,10%)",
            }}
          >
            <LazyLoadImage
              src={data.webPoster}
              alt={data.name}
              width="100%"
              height="auto"
              effect="blur"
            />
          </Box>
        </Stack>
      </ButtonBase>
    </div>
  );
}

export default PosterCard;
