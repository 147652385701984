import React, { Fragment, useState } from "react";
import {
  Container,
  Stack,
  IconButton,
  Typography,
  Button,
  ButtonBase,
} from "@mui/material";

import "./nav.css";

import MenuIcon from "@mui/icons-material/Menu";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { NavLink } from "react-router-dom";
import DrawerMenu from "../DrawerMenu/DrawerMenu";
// Routes names and path
import { RoutesData } from "../RoutesData/RoutesData";
// Contacts Data
import { ContactsData } from "../../Contacts/ContactsData";
import ContactWidget from "../../Contacts/ContactWidget";
import ContactsModal from "../../Contacts/ContactsModal";
import { useCollection } from "../../../CustomHooks/UseCollection";

function MainNav() {
  // Fixed NavBar useing Hooks ---------------------
  const [NavPosation, setNavPosation] = useState(false);
  const fixedNav = () => {
    window.scrollY >= 50 ? setNavPosation(true) : setNavPosation(false);
  };
  window.addEventListener("scroll", fixedNav);

  // Render Nav List Buttons
  const NavListButtons = RoutesData.slice(0, 4).map(
    ({ name, path, icon, iconActive }, index) => {
      return (
        <Button
          key={index}
          component={NavLink}
          to={path}
          variant="text"
          color="inherit"
        >
          {name}
        </Button>
      );
    }
  );

  // Drawer Menu ------------------------
  const [openDrawer, setOpenDrawer] = useState(false);
  const closeDrawerHandeler = () => {
    setOpenDrawer(!openDrawer);
  };

  // Contacts Modal ------------------------
  const [openContactsModal, setOpenContactsModal] = useState(false);
  const closeContactsModal = () => {
    setOpenContactsModal(!openContactsModal);
  };

  // Personal Data from firebase
  const [PersonalData] = useCollection("Personal");
  const { resume } = PersonalData.length > 0 && PersonalData[0];
  return (
    <Fragment>
      <div className={NavPosation ? "mainNav fixedNav" : "mainNav"}>
        <Container fixed>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                className="menuIcon"
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                <MenuIcon />
              </IconButton>

              <ButtonBase component={NavLink} to="/">
                <Typography variant="h3">سرور رشاد</Typography>
              </ButtonBase>
            </Stack>

            <div className="navLinks">
              {NavListButtons}
              <Button
                component="a"
                href={resume}
                target="_blank"
                startIcon={<SaveAltIcon />}
                color="inherit"
                variant="text"
              >
                تحميل السيرة الذاتية
              </Button>
            </div>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                className="contactButton"
                onClick={() => setOpenContactsModal(!openContactsModal)}
              >
                وسائل الإتصال
              </Button>

              {/*Contacts Icons --------------------------------------*/}
              <Stack spacing="8px" direction="row" className="navContactIcon">
                {ContactsData.map(({ name, number, img, link }, index) => {
                  return (
                    <ContactWidget
                      key={index}
                      name={name}
                      number={number}
                      img={img}
                      link={link}
                      hiddenData={true}
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </div>
      {/* Nav Clear --------------------------------------------- */}
      <div className="navClear"></div>
      {/* Drawer Menu --------------------------------------------- */}
      <DrawerMenu open={openDrawer} close={closeDrawerHandeler} />

      {/*Contact Modal*/}
      <ContactsModal open={openContactsModal} close={closeContactsModal} />
    </Fragment>
  );
}

export default MainNav;
