import React, { Fragment } from "react";
import FrontEndSection from "../Components/FrontEndSection/FrontEndSection";
import IntroSection from "../Components/IntroSection/IntroSection";
import MobileSection from "../Components/MobileSection/MobileSection";
import SeSpace from "../Components/SeSpace/SeSpace";
import SkillsSection from "../Components/SkillsSection/SkillsSection";
import WebSection from "../Components/WebSection/WebSection";

function Home() {
  return (
    <Fragment>
      {/*/---------------INTRO---------------*/}
      <IntroSection />
      <SeSpace />
      {/*/---------------Mobile Apps---------*/}
      <MobileSection />
      <SeSpace />
      {/*/---------------Websites------------*/}
      <WebSection />
      <SeSpace />
      {/*/---------------FrontEnd------------*/}
      <FrontEndSection />
      {/*/---------------Skills------------*/}
      <SkillsSection />
    </Fragment>
  );
}

export default Home;
