import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Button, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import style from "./SectionTitle.module.css";

function SectionTitle({ bigTitle, lightTitle, btnRoute, liveIcon }) {
  return (
    <div className={style.SectionTitleContainer}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing="4px">
          <Typography variant="h2" sx={{ fontWeight: "400" }}>
            {lightTitle}
          </Typography>
          <Typography variant="h2">{bigTitle}</Typography>
          {liveIcon && (
            <span className={style.liveicon}>
              <i></i>Live
            </span>
          )}
        </Stack>
        <Button
          variant="outlined"
          color="primary"
          component={NavLink}
          to={btnRoute}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          معرض الأعمال
        </Button>

        {/*responcive Plus icon ---------------------------------*/}
        <IconButton
          className={style.plusIcon}
          component={NavLink}
          to={btnRoute}
          sx={{ display: { xs: "flex", sm: "none" } }}
        >
          <AddIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default SectionTitle;
