import { ButtonBase, Slider, Stack, Typography } from "@mui/material";

import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import styles from "./ClientsAudio.module.css";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

function AudioItem({ audioName, audioURL, audioCountry, audioCountryFlag }) {
  const [audioPlay, setAudioPlay] = useState(false);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 9 ? `0${secondLeft}` : secondLeft}`;
  }

  return (
    <div className={styles.AudioItemContainer}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: "2px", mb: "7px" }}
      >
        <Typography variant="h6">{audioName}</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LazyLoadImage
            effect="blur"
            width="22px"
            height="13px"
            src={audioCountryFlag}
            alt={audioCountry}
            style={{ width: "22px", height: "auto" }}
          />
          <Typography variant="caption">{audioCountry}</Typography>
        </Stack>
      </Stack>

      {/* Player Stack */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <div className={styles.playerButtonsContainer}>
          <ButtonBase
            sx={{ color: "#FB5B0F" }}
            onClick={() => setAudioPlay(true)}
          >
            <PlayCircleOutlineIcon />
          </ButtonBase>
          {audioPlay && (
            <ButtonBase
              sx={{ color: "#FB5B0F", background: "#fff" }}
              onClick={() => setAudioPlay(false)}
            >
              <PauseCircleIcon />
            </ButtonBase>
          )}
        </div>

        <Slider
          aria-label="time-indicator"
          size="small"
          value={position}
          max={duration}
          min={0}
          step={null}
          sx={{ cursor: "default" }}
        />
        <Typography
          variant="caption"
          sx={{ color: audioPlay ? "#FB5B0F" : "#000" }}
        >
          {formatDuration((duration - position).toFixed())}
        </Typography>
      </Stack>

      <ReactPlayer
        url={audioURL}
        playing={audioPlay}
        onDuration={(duration) => {
          setDuration(duration);
        }}
        onProgress={(progress) => {
          setPosition(progress.playedSeconds);
        }}
        onEnded={() => setAudioPlay(false)}
        style={{ display: "none" }}
      ></ReactPlayer>
    </div>
  );
}

export default AudioItem;
