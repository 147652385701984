import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBMMCiXZm-1Rxfzvu1NQCbj6Vp_xXnh3lA",
  authDomain: "srorportfolio.firebaseapp.com",
  projectId: "srorportfolio",
  storageBucket: "srorportfolio.appspot.com",
  messagingSenderId: "951430066021",
  appId: "1:951430066021:web:258d622ecf1eb4136ebb8c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//connect app with firestore database
const db = getFirestore(app);
// export datadase
export default db;
