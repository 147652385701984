import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function ImageCard({ data, clickAction }) {
  return (
    <div style={{ paddingBottom: "16px" }}>
      <ButtonBase onClick={clickAction}>
        <LazyLoadImage
          src={data.poster}
          alt={data.name}
          effect="blur"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            marginBottom: "7px",
          }}
        />
      </ButtonBase>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "0px 10px" }}
      >
        <article>
          <Typography variant="h5" color="initial" mb="1px">
            {data.name}
          </Typography>

          <Typography variant="body2" color="#444">
            {data.description.slice(0, 41)}
            {data.description.length > 41 && (
              <span style={{ paddingRight: "2px" }}>...</span>
            )}
          </Typography>
        </article>

        <Stack direction="row" alignItems="center" spacing="6px">
          <RemoveRedEyeIcon sx={{ fontSize: "18px", color: "#66727A" }} />
          <Typography variant="body1" color="initial">
            {data.viewsCounter}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}

export default ImageCard;
