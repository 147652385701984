import React, { Fragment, useState } from "react";
import { Typography, ButtonBase, Skeleton } from "@mui/material";
import "./LatestWorks.css";

// FireBase ---------------------------------
import { useCollection } from "../../../CustomHooks/UseCollection";
// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { OwlOptions, LatestWorksOptions } from "../../OwlOptions/OwlOptions";
import PreviewDialog from "../../PreviewDialog/PreviewDialog";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../../firebase/firebase";
import { LazyLoadImage } from "react-lazy-load-image-component";

function LatestWorks() {
  //  latest Works Data fromm firebase -----------------------
  const [latestWorksData] = useCollection("latestWorks");
  // item in owl-Carousel ------------------------------------
  const latestWorksItems = latestWorksData.map((item) => {
    return (
      <ButtonBase
        key={item.id}
        className="latestWorkItem"
        onClick={() => previewDialogHandeler(item)}
      >
        <article>
          <Typography variant="h1" color="initial">
            {item.name}
          </Typography>

          <Typography variant="h4" color="initial" mt="20px">
            {item.description}
          </Typography>
        </article>
        <div>
          {item.poster && (
            <LazyLoadImage src={item.poster} alt={item.name} effect="blur" />
          )}
        </div>
      </ButtonBase>
    );
  });

  // Preview dialog data --------------------------------------
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [previewDialogData, setPreviewDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    poster: "",
    icon: "",
    date: "",
    viewsCounter: 0,
    preview_video: "",
    previewImages: ["", ""],
    prototype: "",
  });

  // Preview Dialog handeler -----
  const previewDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "latestWorks", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  return (
    <Fragment>
      <div className="LatestWorksContainer">
        <Typography variant="body1" color="initial" className="latestTitle">
          أخر أعمالى
          <span></span>
        </Typography>

        {/*Render Data ---------------------------*/}
        {latestWorksData.length > 0 && (
          <ReactOwlCarousel
            className="owl-theme"
            {...OwlOptions}
            {...LatestWorksOptions}
            style={{
              position: "initial",
            }}
          >
            {/*Owl Items ----------------------------*/}
            {latestWorksItems}
          </ReactOwlCarousel>
        )}

        {/*Skeleton ------------------------------*/}
        {latestWorksData.length === 0 && (
          <div className="latestWorkItem">
            <article>
              <Skeleton
                variant="rectangular"
                width={210}
                height={18}
                sx={{ borderRadius: "40px" }}
              />

              <Skeleton
                variant="rectangular"
                width={280}
                height={8}
                sx={{ marginTop: "30px", borderRadius: "40px" }}
              />
              <Skeleton
                variant="rectangular"
                width={260}
                height={6}
                sx={{ marginTop: "10px", borderRadius: "40px" }}
              />
            </article>
            <div>
              <Skeleton
                variant="rectangular"
                width="450px"
                height="400px"
                sx={{ marginRight: "30px", borderRadius: "10px" }}
              />
            </div>
          </div>
        )}
      </div>

      {/*Prview Dialog ----------------------------*/}
      <PreviewDialog
        open={openPreviewDialog}
        close={() => setOpenPreviewDialog(!openPreviewDialog)}
        dialogData={previewDialogData}
      />
    </Fragment>
  );
}

export default LatestWorks;
