import {
  Alert,
  Button,
  Snackbar,
  Stack,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import PageDescription from "../Components/PageDescription/PageDescription";
import { motion } from "framer-motion";
function Contacts() {
  // send massage Snackbar ------------------------------
  let locationPath = document.location.href;
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    SetOpenSnackbar(false);
  };
  return (
    <Fragment>
      {/*Start page description Section -----------------*/}
      <PageDescription
        pageName="إرسال رسالة"
        bottomText="سيتم التواصل معك فور الإستلام"
      />
      {/*Contact Form ------------------------------------*/}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.2,
          duration: 0.6,
        }}
      >
        <Container fixed sx={{ paddingBottom: "30px" }}>
          <Typography
            variant="body1"
            color="initial"
            sx={{ padding: "12px 10px" }}
          >
            نموذج الإرسال
          </Typography>
          <form
            action="https://formsubmit.co/srordesigner@gmail.com"
            method="POST"
            onSubmit={() => SetOpenSnackbar(true)}
          >
            <TextField
              type="hidden"
              name="_next"
              value={locationPath}
              sx={{ display: "none" }}
            />

            <TextField
              type="hidden"
              name="_template"
              value="box"
              sx={{ display: "none" }}
            />
            <TextField
              type="hidden"
              name="_captcha"
              value="false"
              sx={{ display: "none" }}
            />
            <TextField type="hidden" name="_honey" sx={{ display: "none" }} />

            <Stack direction="column" spacing="16px">
              <TextField
                label="إسم الكريم"
                required
                variant="outlined"
                fullWidth
                color="info"
                name="_subject"
              />
              <TextField
                label="رقم واتسأب"
                required
                variant="outlined"
                fullWidth
                color="info"
                type="tel"
                name="whatsApp"
              />

              <TextField
                label="الرسالة"
                required
                variant="outlined"
                fullWidth
                multiline
                color="info"
                rows={6}
                name="message"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt="10px"
              >
                إرسال
              </Button>
            </Stack>
          </form>
        </Container>
      </motion.div>
      {/*Send success  -------------------------------------------*/}
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          تم إرسال الرسالة بنجاح
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default Contacts;
