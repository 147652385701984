import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
// Styels ----------------------------------------
import styles from "./BottomMenu.module.css";
// Routes names and path
import { RoutesData } from "../RoutesData/RoutesData";

function BottomMenu() {
  // Get path name
  let location = useLocation();
  let locationPath = location.pathname;

  // Render Buttons
  const buttonsList = RoutesData.map(({ path, icon, iconActive }, index) => {
    return (
      <IconButton key={index} component={NavLink} to={path}>
        {locationPath === path ? iconActive : icon}
      </IconButton>
    );
  });

  return <div className={styles.bottomMenuContainer}>{buttonsList}</div>;
}

export default BottomMenu;
