import { direction } from "../../Theme/Theme";
export const OwlOptions = {
  rtl: direction === "rtl" ? true : false,
  smartSpeed: 450,
  autoplaySpeed: 450,
  navText: ["", ""],
};

// Apps Poster options --------------------------------
export const posterOwlOptions = {
  margin: 20,
  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 2,
    },
    900: {
      items: 3,
      nav: true,
    },
    1200: {
      items: 4,
      nav: true,
    },
    1536: {
      items: 5,
      nav: true,
    },
  },
};

// wesites Poster options --------------------------------
export const WebposterOwlOptions = {
  margin: 20,
  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
    },
    900: {
      items: 2,
      nav: true,
    },
    1200: {
      items: 2,
      nav: true,
    },
    1536: {
      items: 2,
      nav: true,
    },
  },
};

//---------Audio-------------------------
export const audioEvaluationOptions = {
  margin: 16,
  nav: true,

  responsive: {
    0: {
      items: 1,
      dots: true,
      nav: false,
    },

    376: {
      items: 1,
      dots: true,
    },
    600: {
      items: 2,
      dots: false,
    },
    900: {
      items: 2,
      dots: false,
    },
    1200: {
      items: 3,
      dots: false,
    },
    1536: {
      items: 4,
      dots: false,
    },
  },
};

//----------LatestWorksOptions------------------------
export const LatestWorksOptions = {
  margin: 0,
  items: 1,
  nav: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplaySpeed: 600,
};

//----------MobileAppsHomeCarousel------------------------
export const MobileAppsHomeCarousel = {
  margin: 16,

  responsive: {
    0: {
      items: 2,
    },

    600: {
      items: 3,
    },
    900: {
      items: 5,
      nav: true,
    },
    1200: {
      items: 7,
      nav: true,
    },
  },
};

//---------- WebsitesHomeCarousel------------------------
export const WebsitesHomeCarousel = {
  margin: 13,

  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 1,
    },
    900: {
      items: 2,
      nav: true,
    },
    1200: {
      items: 3,
      nav: true,
    },
  },
};

//-------------Technologie Footer---------------------
export const SkillsCarousel = {
  margin: 10,
  dots: true,

  nav: false,
  responsive: {
    0: {
      items: 3,
    },
    414: {
      items: 4,
    },
    600: {
      items: 3,
    },
    900: {
      items: 4,
    },
    1200: {
      items: 6,
    },
  },
};

//-------------CoverMobileApps  ---------------------
export const CoverMobileApps = {
  margin: 11,
  dots: true,

  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 4,
      nav: true,
    },
  },
};

//-------------CoverMobileApps  ---------------------
export const CoverWebsites = {
  margin: 11,
  dots: true,

  responsive: {
    0: {
      items: 1,
    },

    900: {
      items: 2,
    },
    1200: {
      items: 2,
      nav: true,
    },
  },
};

//-------------CoverMobileApps  ---------------------
export const caseStudy = {
  margin: 10,
  dots: true,

  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 1,
    },
    900: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};
