import React, { Fragment } from "react";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import CloudAnimation from "../CloudAnimation/CloudAnimation";
import cloud_bg from "../../assets/images/cloud_bg.png";
import { motion } from "framer-motion";
function PageDescription({ pageName, Description, topText, bottomText }) {
  return (
    <Fragment>
      <Box
        sx={{
          position: "relative",
          padding: "38px 0px 45px 0px",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Container fixed>
          <Stack
            direction="row"
            spacing="22px"
            divider={
              <Divider
                orientation="vertical"
                sx={{ height: "22px", display: { xs: "none", md: "flex" } }}
              />
            }
            alignItems="center"
            justifyContent="center"
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.0,
                duration: 0.1,
                type: "spring",
                stiffness: 120,
              }}
            >
              <Typography variant="h2" color="initial">
                {pageName}
              </Typography>
            </motion.div>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.3,
                type: "spring",
                stiffness: 150,
              }}
            >
              <Typography
                variant="body1"
                color="#333"
                sx={{
                  width: "500px",
                  maxWidth: "100%",
                  display: { xs: "none", md: "block" },
                }}
              >
                {Description}
              </Typography>
            </motion.div>
          </Stack>
        </Container>
        {/*------------Cloudes---------------*/}
        <CloudAnimation
          Duration="120s"
          Delay_1="12s"
          Delay_2="0s"
          Delay_3="3s"
        />
      </Box>

      {/*-----------Mobile View----------*/}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          duration: 0.6,
          type: "spring",
          stiffness: 150,
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <Container fixed>
            <Box
              sx={{
                background: "#FFE082",
                minHeight: "107px",
                borderRadius: "6px",
                alignItems: "center",
                display: "flex",
                padding: "0px 22px",
                position: "relative",
              }}
            >
              <article>
                <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                  {topText}
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  sx={{ fontSize: "1.3rem", mt: "2px" }}
                >
                  {pageName}
                </Typography>
                <Typography variant="body1" color="#333" mt="2px">
                  {bottomText}
                </Typography>
              </article>

              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  margin: "28px 22px",
                  zIndex: "1",
                }}
              >
                <img src={cloud_bg} alt="" />
              </Box>
            </Box>
          </Container>
        </Box>
      </motion.div>
    </Fragment>
  );
}

export default PageDescription;
