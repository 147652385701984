import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import ComputerTwoToneIcon from "@mui/icons-material/ComputerTwoTone";
import CodeIcon from "@mui/icons-material/Code";
import HomeIcon from "@mui/icons-material/Home";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
export const RoutesData = [
  {
    name: "الرئيسية",
    path: "/",
    icon: <HomeOutlinedIcon />,
    iconActive: <HomeIcon sx={{ color: "#FB5B0F" }} />,
  },
  {
    name: "تطبيقات الجوال",
    path: "/mobileApps",
    icon: <PhoneIphoneOutlinedIcon />,
    iconActive: <PhoneIphoneTwoToneIcon sx={{ color: "#FB5B0F" }} />,
  },
  {
    name: "مواقع الإنترنت",
    path: "/websites",
    icon: <ComputerOutlinedIcon />,
    iconActive: <ComputerTwoToneIcon sx={{ color: "#FB5B0F" }} />,
  },
  {
    name: "تكويد الواجهات",
    path: "/frontEnd",
    icon: <CodeIcon />,
    iconActive: <CodeIcon sx={{ color: "#FB5B0F" }} />,
  },
  {
    name: "ارسال رسالة",
    path: "/contacts",
    icon: <EmailOutlinedIcon />,
    iconActive: <EmailTwoToneIcon sx={{ color: "#FB5B0F" }} />,
  },
];
