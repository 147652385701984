import { ButtonBase, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../Theme/Theme";
import { ContactsData } from "../Contacts/ContactsData";
import ContactWidget from "../Contacts/ContactWidget";

import emailImage from "../../assets/images/Email.svg";
import googlePlayIcon from "../../assets/images/google_play.svg";
import appStore from "../../assets/images/app_store.svg";
import ContactsModal from "../Contacts/ContactsModal";
import { useCollection } from "../../CustomHooks/UseCollection";

function Footer() {
  // Contacts Modal ------------------------
  const [openContactsModal, setOpenContactsModal] = useState(false);
  const closeContactsModal = () => {
    setOpenContactsModal(!openContactsModal);
  };

  // Personal Data from firebase
  const [PersonalData] = useCollection("Personal");
  const { appStore: appStoreLink, googlePlay: googlePlayLink } =
    PersonalData.length > 0 && PersonalData[0];

  return (
    <Box sx={{ background: theme.palette.gray, pt: "50px" }}>
      <Container fixed>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "10px",
            padding: { xs: "15px 10px", sm: "25px 30px" },
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Grid container>
            <Grid item xs={12} lg={7}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: "20px", sm: "56px" }}
              >
                {/*Call icon -----------*/}
                {ContactsData.map(({ name, number, img, link }, index) => {
                  return (
                    <ContactWidget
                      key={index}
                      name={name}
                      number={number}
                      img={img}
                      link={link}
                    />
                  );
                })}

                {/*send msg ------------------------*/}
                <ButtonBase
                  onClick={() => setOpenContactsModal(!openContactsModal)}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Stack direction="row" spacing="14px" alignItems="center">
                    <img
                      src={emailImage}
                      alt="email"
                      width="40px"
                      height="40px"
                    />

                    <Stack direction="column" alignItems="flex-start">
                      <Typography variant="body1" sx={{ color: "#555" }}>
                        الإميل
                      </Typography>
                      <Stack
                        direction="row-reverse"
                        alignItems="center"
                        spacing="4px"
                      >
                        <Typography variant="h5">إرسال رسالة</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </ButtonBase>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  sx={{ display: { xs: "none", lg: "flex" } }}
                >
                  <Typography variant="body1" sx={{ color: "#555" }}>
                    تطبيق الجوال
                  </Typography>
                  <Stack
                    direction="row-reverse"
                    alignItems="center"
                    spacing="4px"
                  >
                    <Typography variant="h5">تحميل التطبيق</Typography>
                  </Stack>
                </Stack>
                <Box>
                  <Stack
                    direction="row"
                    spacing="10px"
                    sx={{ pt: { xs: "25px", lg: "0px" } }}
                  >
                    <ButtonBase
                      component="a"
                      href={googlePlayLink}
                      target="_blank"
                    >
                      <img src={googlePlayIcon} alt="googlePaly" />
                    </ButtonBase>
                    <ButtonBase
                      component="a"
                      href={appStoreLink}
                      target="_blank"
                    >
                      <img src={appStore} alt="appStore" />
                    </ButtonBase>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/*Contact Modal*/}
      <ContactsModal open={openContactsModal} close={closeContactsModal} />
    </Box>
  );
}

export default Footer;
