import { Container, Skeleton, Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import "./MobileSection.css";
// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { MobileAppsHomeCarousel, OwlOptions } from "../OwlOptions/OwlOptions";
import IconCard from "../Cards/IconCard/IconCard";
import { useCollection } from "../../CustomHooks/UseCollection";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../firebase/firebase";
import PreviewDialog from "../PreviewDialog/PreviewDialog";
import ImageCard from "../Cards/ImageCard/ImageCard";
import { NavLink } from "react-router-dom";

function MobileSection() {
  //  MobileApps Data from  firebase -----------------------
  const [mobileAppsData] = useCollection("MobileApps");
  // items in owl-Carousel ------------------------------------
  const iconCardsList = mobileAppsData.slice(0, 10).map((item) => {
    return (
      <IconCard
        key={item.id}
        cardType="mobile"
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });
  // image Cards List  ------------------------------------
  const imageCardsList = mobileAppsData.slice(0, 6).map((item) => {
    return (
      <ImageCard
        key={item.id}
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // Preview dialog data --------------------------------------
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [previewDialogData, setPreviewDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    poster: "",
    cover: "",
    coverColor: "",
    icon: "",
    date: "",
    viewsCounter: 0,
    preview_video: "",
    previewImages: ["", ""],
    prototype: "",
  });

  // Preview Dialog handeler -----
  const previewDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "MobileApps", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  return (
    <Fragment>
      <section>
        <Container fixed>
          {/*--------Section Title --------------------*/}
          <SectionTitle
            lightTitle="أعمالى"
            bigTitle="تطبيقات الجوال"
            btnRoute="/mobileApps"
          />

          {/*--------Section Carousel --------------------*/}
          <div className="MobileAppsHomeCarouselContainer">
            {mobileAppsData.length > 0 && (
              <ReactOwlCarousel
                className="owl-theme"
                {...OwlOptions}
                {...MobileAppsHomeCarousel}
                style={{ position: "initial" }}
              >
                {/*items in Carousel */}
                {iconCardsList}
              </ReactOwlCarousel>
            )}

            {/*Skeleton -------------------------- */}
            {mobileAppsData.length === 0 && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="163px"
                sx={{ marginTop: "30px", borderRadius: "10px" }}
              />
            )}
          </div>

          {/*-------- responcive Preview --------------------*/}
          <div className="MobileAppResponcivePreview">
            {imageCardsList}
            {/*Skeleton -------------------------- */}
            {mobileAppsData.length === 0 && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="163px"
                sx={{ borderRadius: "10px" }}
              />
            )}

            <Button
              component={NavLink}
              to="/mobileApps"
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ marginTop: "10px  " }}
            >
              عرض الكل
            </Button>
          </div>
        </Container>
      </section>

      {/*Prview Dialog ----------------------------*/}
      <PreviewDialog
        open={openPreviewDialog}
        close={() => setOpenPreviewDialog(!openPreviewDialog)}
        dialogData={previewDialogData}
      />
    </Fragment>
  );
}

export default MobileSection;
