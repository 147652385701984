import React from "react";
import style from "./cloud.module.css";
import bigCloud from "../../assets/images/cloud_big.svg";
import smallCloud from "../../assets/images/cloud_small.svg";
import { Box } from "@mui/material";

function CloudAnimation({ Duration, Delay_1, Delay_2, Delay_3 }) {
  const cloudStyle = {
    position: "absolute",
    top: "0",
    right: "-100px",
    animationName: "moveCloude",
    animationIterationCount: "infinite",
    animationDuration: Duration,
  };
  return (
    <div className={style.cloudContainer}>
      <Box
        sx={{
          ...cloudStyle,
          animationDelay: Delay_1,
          top: "20px",
        }}
      >
        <img src={smallCloud} alt="cloud" />
      </Box>
      <Box
        sx={{
          ...cloudStyle,
          animationDelay: Delay_2,
          top: "41px",
        }}
      >
        <img src={bigCloud} alt="cloud" />
      </Box>
      <Box
        sx={{
          ...cloudStyle,
          animationDelay: Delay_3,
          top: "109px",
        }}
      >
        <img src={smallCloud} alt="cloud" />
      </Box>
    </div>
  );
}

export default CloudAnimation;
