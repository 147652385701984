import React from "react";
import style from "./TotalResultsBar.module.css";
import Typography from "@mui/material/Typography";

function TotalResultsBar({ totalResults }) {
  return (
    <div className={style.totalBarContainer}>
      <Typography variant="body2" color="initial">
        إجمالى النتائج ( {totalResults} )
      </Typography>
      <div>{/*line*/}</div>
    </div>
  );
}

export default TotalResultsBar;
