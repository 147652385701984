import React, { Fragment, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import PageDescription from "../Components/PageDescription/PageDescription";
import CoverCard from "../Components/Cards/CoverCard/CoverCard";

// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import {
  caseStudy,
  CoverMobileApps,
  OwlOptions,
} from "../Components/OwlOptions/OwlOptions";
import IconCard from "../Components/Cards/IconCard/IconCard";
import TotalResultsBar from "../Components/TotalResultsBar/TotalResultsBar";
import ImageCard from "../Components/Cards/ImageCard/ImageCard";
import CaseStudyCard from "../Components/Cards/CaseStudyCard/CaseStudyCard";
import { useCollection } from "../CustomHooks/UseCollection";
import PreviewDialog from "../Components/PreviewDialog/PreviewDialog";
import { doc, updateDoc } from "firebase/firestore";
import db from "../firebase/firebase";

import { motion } from "framer-motion";
function MobileApps() {
  //  MobileApps Data from  firebase -----------------------
  const [mobileAppsData] = useCollection("MobileApps");
  const totalResults = mobileAppsData.length > 0 && mobileAppsData.length;

  const coverCardsList = mobileAppsData.map((item) => {
    return (
      <CoverCard
        key={item.id}
        cardType="mobileAppCover"
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // icons cards ------------------------------------
  const iconCardsList = mobileAppsData.map((item) => {
    return (
      <Grid key={item.id} item xs={4} sm={3} lg={2}>
        <IconCard
          cardType="mobile"
          data={item}
          clickAction={() => previewDialogHandeler(item)}
        />
      </Grid>
    );
  });

  // image Cards List  ------------------------------------
  const imageCardsList = mobileAppsData.map((item) => {
    return (
      <ImageCard
        key={item.id}
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // Preview dialog data --------------------------------------
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [previewDialogData, setPreviewDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    poster: "",
    cover: "",
    coverColor: "",
    icon: "",
    date: "",
    viewsCounter: 0,
    preview_video: "",
    previewImages: ["", ""],
    prototype: "",
  });

  // Preview Dialog handeler -----
  const previewDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "MobileApps", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  //--------------- Case Study -----------------------------------
  //  Case Study Data from  firebase -----------------------
  const [CaseStudyData] = useCollection("caseStudy");

  // case study cards ------------------------------------
  const caseStudyCardsList = CaseStudyData.map((item) => {
    return (
      <CaseStudyCard
        key={item.id}
        data={item}
        clickAction={() => caseStudyDialogHandeler(item)}
      />
    );
  });

  // case study Dialog handeler -----
  const caseStudyDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "caseStudy", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  return (
    <Fragment>
      {/*Start page description Section ---------------------*/}
      <PageDescription
        topText="اعمالى"
        pageName="تصميم تطبيقات الجوال"
        Description="اقدم الخدمة طبقاً لقواعد منظمة من دارسة متطلبات السوق للخدمة المقدمة من خلال التطبيق مرورا بتجربة المستخدم من بداية الدخول على التطبيق حتى طلب الخدمة
        "
      />

      {/*Start Covers Section --------------------------------*/}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Container fixed>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              duration: 0.6,
              type: "spring",
              stiffness: 150,
            }}
          >
            <div className="coverCarouselContainer">
              {mobileAppsData.length > 0 && (
                <ReactOwlCarousel
                  className="owl-theme"
                  {...OwlOptions}
                  {...CoverMobileApps}
                  style={{ position: "initial" }}
                >
                  {/*items in Carousel */}
                  {coverCardsList}
                </ReactOwlCarousel>
              )}
            </div>
          </motion.div>
          {/*Skeleton -------------------------- */}
          {mobileAppsData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="400px"
              sx={{ borderRadius: "10px", backgroundColor: "#fff" }}
            />
          )}
        </Container>
      </Box>

      {/*Start icons Cards Section ----------------------------*/}
      <Box sx={{ padding: "46px 0px", display: { xs: "none", sm: "block" } }}>
        <Container fixed>
          <Grid
            container
            spacing="12px"
            columns={{ xs: "12", md: "12", lg: "14", xl: "14" }}
          >
            {iconCardsList}
          </Grid>

          {/*Skeleton -------------------------- */}
          {mobileAppsData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="180px"
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Container>
      </Box>

      {/*Start image Cards for mobile view Section ------------*/}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Container fixed>
          {/*Total bar ------*/}
          <TotalResultsBar totalResults={totalResults || 0} />
          {imageCardsList}

          {/*Skeleton -------------------------- */}
          {mobileAppsData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="180px"
              sx={{ borderRadius: "10px" }}
            />
          )}
        </Container>
      </Box>

      {/*Start Case Study Section -----------------------------*/}
      {CaseStudyData.length >= 1 && (
        <Box
          sx={{
            background: { xs: "#FFE082", sm: "#fff" },
            margin: { xs: "46px 0px 0px 0px", sm: "30px 0px 70px 0px" },
          }}
        >
          <Container fixed>
            <Box
              sx={{
                background: "#FFE082",
                borderRadius: "8px",
                padding: { xs: "20px 0px", sm: "32px 79px" },
              }}
            >
              {/*Title ---------------------------------------*/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px 34px 0px",
                }}
              >
                <Stack direction="row" spacing="4px">
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: "1.4rem", sm: "1.375rem" },
                    }}
                  >
                    مراحل
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: "1.4rem", sm: "1.625rem" },
                    }}
                  >
                    بناء وتنفيذ التصميمات
                  </Typography>
                </Stack>
              </div>
              {/*Cards ---------------------------------------*/}
              {CaseStudyData.length > 0 && (
                <ReactOwlCarousel
                  className="owl-theme"
                  {...OwlOptions}
                  {...caseStudy}
                  style={{ position: "initial" }}
                >
                  {caseStudyCardsList}
                </ReactOwlCarousel>
              )}
            </Box>
          </Container>
        </Box>
      )}
      {/*Prview Dialog ----------------------------*/}
      <PreviewDialog
        open={openPreviewDialog}
        close={() => setOpenPreviewDialog(!openPreviewDialog)}
        dialogData={previewDialogData}
      />
    </Fragment>
  );
}

export default MobileApps;
