import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import style from "./PreviewDialog.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Stack, Typography, IconButton } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ReactPlayer from "react-player";
import ContactWidget from "../Contacts/ContactWidget";
import { ContactsData } from "../Contacts/ContactsData";
import srorLogo from "../../assets/images/logo.svg";
import Iframe from "react-iframe";

function PreviewDialog({ open, close, dialogData }) {
  // data dialog spreed
  const {
    name,
    description,
    icon,
    date,
    viewsCounter,
    preview_video,
    previewImages,
    prototype,
  } = dialogData;

  // Dialog style ---------------------------------------------
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const dialogPaperStyle = {
    width: "1254px",
    maxWidth: "100%",
    "@media(min-width: 1367px)": {
      width: "1330px",
    },
  };

  // Scroll to top
  const scrollTopREf = useRef(null);
  const executeScrollTop = () =>
    scrollTopREf.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const scrollBottomREf = useRef(null);
  const executeScrollBottom = () =>
    scrollBottomREf.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const scrollToProtoType = useRef(null);
  const executeScrollToPrototype = () =>
    scrollToProtoType.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const scrollToVideoPlayer = useRef(null);
  const executeScrollToVideoPlayer = () =>
    scrollToVideoPlayer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  // ------------------------------------------------------------
  // Mobile prototype frame overlay
  const [frameOverlay, setFrameOverlay] = useState(true);
  // video voulme

  const [videoVolume, setVideoVolume] = useState(0);

  return (
    <Dialog
      PaperProps={{
        sx: { ...dialogPaperStyle },
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        setFrameOverlay(true);
        close();
        setVideoVolume(0);
      }}
      scroll="body"
    >
      {/*scrollTopREf ------------------------------*/}
      <span ref={scrollTopREf}>{/*Scroll top*/}</span>
      {/*dialogHeader ------------------------------*/}
      <div className={style.dialogHeader}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          alignItems={{ xs: "center", sm: "center" }}
        >
          {/*logo side*/}
          <Box>
            <Stack direction="row" alignItems="center" spacing="15px">
              <div className={style.projectIconContainer}>
                <LazyLoadImage src={icon} alt={name} effect="blur" />
              </div>
              <article>
                <Typography variant="h2" color="initial">
                  {name}
                </Typography>
                <Typography variant="body2" color="#444">
                  {description}
                </Typography>
              </article>
            </Stack>
          </Box>
          {/*date and views side*/}
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing="20px"
              sx={{ display: { xs: "none", sm: "flex" } }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack direction="row" alignItems="center" spacing="8px">
                <RemoveRedEyeIcon sx={{ fontSize: "18px" }} />
                <Typography variant="body1" color="initial">
                  {viewsCounter}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing="8px">
                <EventNoteIcon sx={{ fontSize: "18px" }} />
                <Typography variant="body1" color="initial">
                  {date}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </div>

      {/*dialogImages ------------------------------*/}
      <div className={style.dialogImages}>
        {previewImages.map((imgUrl, index) => {
          return (
            <LazyLoadImage src={imgUrl} alt={name} key={index} effect="blur" />
          );
        })}
      </div>

      {/*scrollToProtoType ------------------------------*/}
      <span ref={scrollToProtoType}>{/*Scrolling ref*/}</span>
      {/* protoType -------------------------*/}

      {prototype && (
        <div className={style.protoType}>
          <Stack direction="row" alignItems="center" spacing="40px">
            <article className={style.frameArticle}>
              <Typography variant="h1" color="#000">
                استخدم التطبيق بنفسك
              </Typography>
              <Typography variant="h4" color="#000" mt="20px">
                يمكنك الضغط والتنقل بين صفحات التطبيق بكل سهولة
              </Typography>
            </article>
            <div className={style.frameContainer}>
              <Iframe
                url={prototype}
                width="375px"
                height="812px"
                id="protoTypeFrame"
                frameBorder="0"
                className="myClassname"
                display="block"
                position="relative"
              />
              <div
                className={style.frameOverlay}
                style={{ display: frameOverlay ? "flex" : "none" }}
                onClick={() => setFrameOverlay(false)}
              >
                <div style={{ animation: "protoTypeCursor 1.5s infinite" }}>
                  <SwipeUpIcon fontSize="inherit" />
                </div>
              </div>
            </div>
          </Stack>
        </div>
      )}

      {/*scrollToVideoPlayer ref ----------------------------*/}
      <span ref={scrollToVideoPlayer}>{/*Scrolling ref*/}</span>

      {/*dialog Video Player -----------------------*/}
      {preview_video && (
        <div>
          <Box
            sx={{
              textAlign: "center",
              background: "#333",
              padding: "10px 0px",
            }}
          >
            <Typography variant="h5" color="#fff">
              بالفيديو التصميم من داخل بئية العمل
            </Typography>
          </Box>
          <div className={style.dialogVideoPlayer}>
            <ReactPlayer
              // light={true}
              url={preview_video}
              width="100%"
              height="100%"
              playing
              controls
              loop
              volume={videoVolume}
            />
          </div>
        </div>
      )}

      {/*Call data  -------------------------*/}
      <div className={style.callDataContainer}>
        <div className={style.myLogo}>
          <img src={srorLogo} alt="sror" />
        </div>
        <div className={style.contactsMethods}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: "20px", sm: "30px" }}
          >
            {/*Call icon -----------*/}
            {ContactsData.map(({ name, number, img, link }, index) => {
              return (
                <ContactWidget
                  key={index}
                  name={name}
                  number={number}
                  img={img}
                  link={link}
                />
              );
            })}
          </Stack>
        </div>
      </div>

      {/*close dilaog fixed btn -------------------------*/}
      <IconButton
        className={style.closeBtn}
        onClick={() => {
          setFrameOverlay(true);
          close();
          setVideoVolume(0);
        }}
      >
        <CloseIcon />
      </IconButton>
      {/*Scroll Down  -------------------------*/}
      <IconButton className={style.ScrollDownBtn} onClick={executeScrollBottom}>
        <ArrowDownwardIcon />
      </IconButton>

      {/*Scroll Top  -------------------------*/}
      <IconButton className={style.ScrollTopBtn} onClick={executeScrollTop}>
        <ArrowUpwardIcon />
      </IconButton>

      {/*Scroll to prototype  -------------------------*/}
      {prototype && (
        <IconButton
          className={style.ScrollToPrototype}
          onClick={executeScrollToPrototype}
        >
          <SwipeLeftIcon className={style.swipeIcon} />
        </IconButton>
      )}

      {/*Scroll to VideoPlayer  -------------------------*/}
      {preview_video && (
        <IconButton
          className={style.ScrollToVideoPlayer}
          onClick={() => {
            executeScrollToVideoPlayer();
            setVideoVolume(0.7);
          }}
        >
          <PlayCircleOutlineIcon className={style.videoIcon} />
        </IconButton>
      )}

      {/*scrollBottomREf ------------------------------*/}
      <span ref={scrollBottomREf}>{/*Scroll top*/}</span>
    </Dialog>
  );
}

export default PreviewDialog;
