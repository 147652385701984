import { createTheme } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { blueGrey } from "@mui/material/colors";

export const direction = "rtl";

// Main Theme ----------------------------------------------
export const theme = createTheme({
  direction: direction,

  // typography--------------------------------------------------------
  typography: {
    fontFamily: direction === "rtl" ? "expo-arabic" : "Segoe UI, Roboto",

    h1: {
      fontSize: "2.25rem",
      fontWeight: "700",
      color: "#000000",
      // lineHeight: "38px",
    },

    h2: {
      fontSize: "1.625rem",
      fontWeight: "700",
      color: "#000000",
    },

    h3: {
      fontSize: "1.625rem",
      fontWeight: "600",
      // lineHeight: "28px",
      color: "#000000",
    },

    h4: {
      fontSize: "1.375rem",
      fontWeight: "300",
      color: "#000000",
      lineHeight: "36px",
    },

    h5: {
      fontSize: "0.875rem",
      fontWeight: "600",
      color: "#000000",
    },

    h6: {
      fontSize: "0.75rem",
      fontWeight: "600",
      color: "#000000",
    },

    body1: {
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
      color: "#000000",
    },

    body2: {
      fontSize: "0.688rem",
      fontWeight: "400",
      lineHeight: "1.25rem",
      color: "#000000",
    },
    // caption: {
    //   fontSize: "0.625rem",
    //   fontWeight: "400",
    //   color: blueGrey[900],
    //   // lineHeight: "1.25rem",
    // },
  },

  // components--------------------------------------------------------
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            color: "#FB5B0F",
            borderColor: "#FB5B0F",
            minWidth: "155px",
            ":hover": {
              color: "#FB5B0F",
              // background: "#000",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          // Some CSS
          fontSize: "0.75rem",
          fontWeight: "500",
          borderRadius: "6px",
        },
        sizeMedium: {
          height: "48px",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          width: "40px",
          height: "40px",
          border: "1px solid #eceff1",

          background: "#fff",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: blueGrey[100],
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        leaveDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          marginTop: "6px !important",
          background: "rgba(0,0,0,0.8) ",
          borderRadius: "8px",
          padding: "8px 12px",
          fontSize: "0.7rem",
          fontWeight: 400,
          color: "#E6E6E6",
          boxShadow: "0px 3px 4px rgba(0,0,0,0.2)",
        },
      },
    },

    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "0.875rem",
          fontWeight: "500",
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        color: "info",
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",

          "& fieldset": {
            borderColor: "#C7CADB",
          },
        },
      },
    },
  },

  // palette --------------------------------------------------------

  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1340,
  //   },
  // },

  // palette --------------------------------------------------------
  palette: {
    type: "light",
    primary: {
      main: "#FB5B0F",
      light: "#FB5B0F",
      dark: "#E34F08",
    },
    secondary: {
      main: "#f50057",
    },

    gray: "#E8EAF6",
    orange: "#FFE082",
  },
});

// breakpoints

// Mui RTL styles ------------------------------------------
export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: direction === "rtl" && [rtlPlugin],
});
