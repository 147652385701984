import { Stack, Button, Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import style from "./FrontPosterCard.module.css";
function FrontPosterCard({ data, clickAction }) {
  return (
    <div className={style.FrontPosterCardContainer}>
      <div className={style.dataSide}>
        <Stack direction="column" spacing="29px">
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: "25px", lg: "0px" }}
            onClick={clickAction}
          >
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              <LazyLoadImage
                src={data.icon}
                alt={data.name}
                width="110px"
                height="100px"
                effect="blur"
                style={{ borderRadius: "10px" }}
              />
            </Box>

            <article>
              <Typography
                variant="h2"
                color="initial"
                mb="3px"
                sx={{ fontSize: "1.5rem" }}
              >
                {data.name}
              </Typography>

              <Typography variant="body2" color="#444">
                {data.description.slice(0, 41)}
                {data.description.length > 41 && (
                  <span style={{ paddingRight: "2px" }}>...</span>
                )}
              </Typography>
            </article>
          </Stack>

          <Divider />
          {/*technologies ----------*/}
          <Box>
            <Typography variant="body1" color="initial" mb="10px">
              التقنيات المستخدمة
            </Typography>
            <Stack direction="row" spacing="5px">
              {data.technologies.map((item, index) => {
                return (
                  <LazyLoadImage
                    key={index}
                    src={item}
                    alt={data.name}
                    effect="blur"
                    width="33.78px"
                    height="33.78px"
                    style={{ borderRadius: "4px" }}
                  />
                );
              })}
            </Stack>
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={clickAction}
          >
            مشاهدة الموقع
          </Button>
        </Stack>
      </div>
      <div className={style.posterSide} onClick={clickAction}>
        <span>
          <LazyLoadImage src={data.poster} alt={data.name} effect="blur" />
        </span>
      </div>
    </div>
  );
}

export default FrontPosterCard;
