import React from "react";
import { Container, Grid } from "@mui/material";
import PersonalBox from "./PersonalBox/PersonalBox";
import LatestWorks from "./LatestWorks/LatestWorks";
import { motion } from "framer-motion";

function IntroSection() {
  return (
    <section>
      <Container fixed>
        <Grid container columnSpacing="16px">
          <Grid
            item
            xs={12}
            lg={3}
            sx={{ display: { xs: "block", md: "none", lg: "block" } }}
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0,
                duration: 0.5,
                type: "spring",
                stiffness: 150,
              }}
            >
              {/*------------------------*/}
              <PersonalBox />
              {/*------------------------*/}
            </motion.div>
          </Grid>

          <Grid
            item
            xs={12}
            lg={9}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.6,
                type: "spring",
                stiffness: 150,
              }}
            >
              {/*------------------------*/}
              <LatestWorks />
              {/*------------------------*/}
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default IntroSection;
