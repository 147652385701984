import { Button, Container, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useState } from "react";
import FrontSmallCard from "../Cards/FrontSmallCard/FrontSmallCard";
import SectionTitle from "../SectionTitle/SectionTitle";
import { NavLink } from "react-router-dom";
import LiveDemoDialog from "../LiveDemoDialog/LiveDemoDialog";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../firebase/firebase";
import { useCollection } from "../../CustomHooks/UseCollection";

function FrontEndSection() {
  //  frontEnd Data from  firebase -----------------------
  const [frontEndData] = useCollection("frontEnd");
  // Cards List
  const cardsList = frontEndData.slice(0, 6).map((item) => {
    return (
      <Grid key={item.id} item xs={12} md={6} lg={4}>
        <FrontSmallCard
          data={item}
          clickAction={() => LiveDemoDialogHandeler(item)}
        />
      </Grid>
    );
  });

  // Live Demo Dialog data ----------------------------------------
  const [openLiveDemoDialog, setOpenLiveDemoDialog] = useState(false);

  const [liveDemoDialogDialogData, setLiveDemoDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    icon: "",
    poster: "",
    frameUrl: "",
    date: "",
    viewsCounter: 0,
    technologies: [],
  });

  // iframe Skelton
  const [displaySkelton, setDisplaySkelton] = useState(true);

  // Preview Dialog handeler -----
  const LiveDemoDialogHandeler = async (itemData) => {
    setLiveDemoDialogData(itemData);
    setOpenLiveDemoDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "frontEnd", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });

    // hide frame skeleton after 1.5 scounds
    setTimeout(() => {
      setDisplaySkelton(false);
    }, 1500);
  };

  // close Live Demo Dialog Handeler------------------------
  const closeLiveDemoDialogHandeler = () => {
    // close dialog
    setOpenLiveDemoDialog(false);
    // reset frame skeleton
    setDisplaySkelton(true);
  };
  return (
    <Fragment>
      <section>
        <Container fixed>
          {/*--------Section Title --------------------*/}
          <SectionTitle
            lightTitle="أعمالى"
            bigTitle="تكويد واجهات المواقع"
            btnRoute="/frontEnd"
            liveIcon="true"
          />
          {/*--------Cards --------------------*/}
          <Grid container spacing="12px">
            {cardsList}
          </Grid>
          {/*Skeleton -------------------------- */}
          {frontEndData.length === 0 && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="163px"
              sx={{ borderRadius: "10px" }}
            />
          )}

          {/*-------- More Button in mobile view --------------------*/}
          <Button
            component={NavLink}
            to="/frontEnd"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              marginTop: "30px",
              display: "none",
              "@media(max-width:600px)": { display: "flex" },
            }}
          >
            عرض الكل
          </Button>
        </Container>
      </section>

      {/*-------- LiveDemoDialog --------------------*/}
      <LiveDemoDialog
        open={openLiveDemoDialog}
        close={closeLiveDemoDialogHandeler}
        data={liveDemoDialogDialogData}
        displaySkelton={displaySkelton}
      />
    </Fragment>
  );
}

export default FrontEndSection;
