import { ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function FrontSmallCard({ data, clickAction }) {
  const FrontSmallCardStyle = {
    background: "#fff",
    textAlign: "left",
    display: "block",
    transition: "background 0.3s ease",
    border: "1px solid #E8EAF6",
    borderRadius: "6px",
    padding: "9px 30px",
    width: "100%",
    minHeight: "94px",

    "&:hover": {
      background: "#E8EAF6",
    },
  };
  return (
    <ButtonBase
      sx={{
        ...FrontSmallCardStyle,
      }}
      onClick={clickAction}
    >
      <Stack direction="row" alignItems="center" spacing="40px">
        <LazyLoadImage
          src={data.icon}
          alt={data.name}
          width="84px"
          height="76px"
          effect="blur"
          style={{ borderRadius: "4px" }}
        />
        <article>
          <Typography variant="h5" color="initial" mb="3px">
            {data.name}
          </Typography>

          <Typography variant="body2" color="#444">
            {data.description.slice(0, 41)}
            {data.description.length > 41 && (
              <span style={{ paddingRight: "2px" }}>...</span>
            )}
          </Typography>
          <Typography variant="body2" color="#888" sx={{ fontSize: "0.6rem" }}>
            {data.viewsCounter} مشاهدة
          </Typography>
        </article>
      </Stack>
    </ButtonBase>
  );
}

export default FrontSmallCard;
