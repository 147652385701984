import { Container, Skeleton, Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import "./WebSection.css";
// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { OwlOptions, WebsitesHomeCarousel } from "../OwlOptions/OwlOptions";

import { useCollection } from "../../CustomHooks/UseCollection";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../firebase/firebase";
import PreviewDialog from "../PreviewDialog/PreviewDialog";
import ImageCard from "../Cards/ImageCard/ImageCard";
import { NavLink } from "react-router-dom";
import PosterCard from "../Cards/PosterCard/PosterCard";

function WebSection() {
  //  MobileApps Data from  firebase -----------------------
  const [WebsitesData] = useCollection("Websites");
  // items in owl-Carousel ------------------------------------
  const posterCardsList = WebsitesData.slice(0, 10).map((item) => {
    return (
      <PosterCard
        key={item.id}
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });
  // image Cards List  ------------------------------------
  const imageCardsList = WebsitesData.slice(0, 6).map((item) => {
    return (
      <ImageCard
        key={item.id}
        data={item}
        clickAction={() => previewDialogHandeler(item)}
      />
    );
  });

  // Preview dialog data --------------------------------------
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [previewDialogData, setPreviewDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    poster: "",
    webPoster: "",
    cover: "",
    coverColor: "",
    icon: "",
    date: "",
    viewsCounter: 0,
    preview_video: "",
    previewImages: ["", ""],
  });
  // Preview Dialog handeler -----
  const previewDialogHandeler = async (itemData) => {
    setPreviewDialogData(itemData);
    setOpenPreviewDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "Websites", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });
  };

  return (
    <Fragment>
      <section>
        <Container fixed>
          {/*--------Section Title --------------------*/}
          <SectionTitle
            lightTitle="أعمالى"
            bigTitle="مواقع الإنترنت"
            btnRoute="/websites"
          />

          {/*--------Section Carousel --------------------*/}
          <div className="WebsitesHomeCarouselContainer">
            {WebsitesData.length > 0 && (
              <ReactOwlCarousel
                className="owl-theme"
                {...OwlOptions}
                {...WebsitesHomeCarousel}
                style={{ position: "initial" }}
              >
                {/*items in Carousel */}
                {posterCardsList}
              </ReactOwlCarousel>
            )}

            {/*Skeleton -------------------------- */}
            {WebsitesData.length === 0 && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="163px"
                sx={{ marginTop: "30px", borderRadius: "10px" }}
              />
            )}
          </div>

          {/*-------- responcive Preview --------------------*/}
          <div className="WebsitesResponcivePreview">
            {imageCardsList}
            {/*Skeleton -------------------------- */}
            {WebsitesData.length === 0 && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="163px"
                sx={{ borderRadius: "10px" }}
              />
            )}

            <Button
              component={NavLink}
              to="/websites"
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ marginTop: "10px  " }}
            >
              عرض الكل
            </Button>
          </div>
        </Container>
      </section>

      {/*Prview Dialog ----------------------------*/}
      <PreviewDialog
        open={openPreviewDialog}
        close={() => setOpenPreviewDialog(!openPreviewDialog)}
        dialogData={previewDialogData}
      />
    </Fragment>
  );
}

export default WebSection;
