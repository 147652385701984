import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import AudioItem from "./AudioItem";

// Owl Carousel ------------------------
import ReactOwlCarousel from "react-owl-carousel-rtl";
import { audioEvaluationOptions, OwlOptions } from "../OwlOptions/OwlOptions";

// Styles and theme ------------------------
import styles from "./ClientsAudio.module.css";
import { theme } from "../../Theme/Theme";

// FireBase ---------------------------------
import { useCollection } from "../../CustomHooks/UseCollection";

function AudioEvaluation() {
  // Audio Reviews Data fromm firebase
  const [mainData] = useCollection("audioReviews");

  return (
    <Box sx={{ background: theme.palette.gray }}>
      {mainData.length > 0 && (
        <Container fixed>
          <div className={styles.audioEvaluationConatiner}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                sx={{
                  width: { xs: "100%", md: "230px" },
                  textAlign: { xs: "center", md: "left" },
                  paddingBottom: { xs: "20px", md: "0px" },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "400 !important",
                    mb: "10px",
                    fontSize: { xs: "1.3rem", sm: "1.3rem" },
                  }}
                >
                  إستمع إلى أراء
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "1.3rem", sm: "1.5rem" },
                  }}
                >
                  الشركات والعملاء
                </Typography>
              </Grid>
              <Grid
                item
                sx={{ width: { xs: "100%", md: "calc(100% - 230px)" } }}
              >
                <div className="audioCarsoulArrowContainer">
                  <ReactOwlCarousel
                    className="owl-theme"
                    {...OwlOptions}
                    {...audioEvaluationOptions}
                  >
                    {mainData.map((item) => {
                      const {
                        id,
                        AudioTitle,
                        AudioUrl,
                        AudioCountryName,
                        AudioCountryFlagUrl,
                      } = item;
                      return (
                        <div key={id} className="item">
                          <AudioItem
                            audioName={AudioTitle}
                            audioURL={AudioUrl}
                            audioCountry={AudioCountryName}
                            audioCountryFlag={AudioCountryFlagUrl}
                          />
                        </div>
                      );
                    })}
                  </ReactOwlCarousel>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      )}
    </Box>
  );
}

export default AudioEvaluation;
