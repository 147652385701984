import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/system";
import logoImage from "../../../assets/images/logo.svg";

import {
  Drawer,
  ButtonBase,
  Typography,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
// contact Data
import { ContactsData } from "../../Contacts/ContactsData";
// Routes names and path
import { RoutesData } from "../RoutesData/RoutesData";
import ContactWidget from "../../Contacts/ContactWidget";
import { useCollection } from "../../../CustomHooks/UseCollection";

function DrawerMenu({ open, close }) {
  //drower item Selected
  const [selectedItem, setselectedItem] = useState(0);

  // Render menu List Buttons
  const menuListButtons = RoutesData.slice(0, 4).map(
    ({ name, path, icon, iconActive }, index) => {
      return (
        <ListItem key={index} disablePadding>
          <ListItemButton
            component={NavLink}
            to={path}
            selected={selectedItem === index}
            onClick={() => {
              setselectedItem(index);
              close();
            }}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      );
    }
  );

  // send msg button on responcive view
  const MobileBtn = {
    display: "none",
    "@media (max-width: 600px)": {
      display: "flex",
    },
  };

  // Personal Data from firebase
  const [PersonalData] = useCollection("Personal");
  const { resume } = PersonalData.length > 0 && PersonalData[0];

  return (
    <Drawer anchor="left" open={open} onClose={close}>
      <Box sx={{ width: "300px" }}>
        {/*-------Start Logo ------*/}
        <Box sx={{ padding: "35px 25px 30px 25px" }}>
          <ButtonBase component={NavLink} to="/" onClick={close}>
            <img src={logoImage} width="69.3px" height="75.4px" alt="سرور" />
          </ButtonBase>
          <Typography
            variant="h3"
            sx={{ padding: "4px 0px", marginTop: "10px" }}
          >
            سرور رشاد
          </Typography>
          <Typography variant="body1" component="h1" sx={{ color: "#555" }}>
            مصمم تطبيقات جوال ومواقع إنترنت
          </Typography>
        </Box>
        {/*-------End Logo ------*/}

        {/*-------Start Menu List ------*/}
        <List>
          {menuListButtons}
          {/*----------------------------*/}
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href={resume}
              target="_blank"
              onClick={() => {
                close();
              }}
            >
              <ListItemIcon>
                <SaveAltIcon />
              </ListItemIcon>
              <ListItemText primary="تحميل السيرة الذاتية" />
            </ListItemButton>
          </ListItem>
        </List>
        {/*-------End Menu List ------*/}
        <Divider sx={{ margin: "20px 0px 8px 0px" }} />

        <Stack
          direction="column"
          alignItems="flex-start"
          spacing="18px"
          sx={{ padding: "  15px" }}
        >
          <Typography variant="body2" color="#666" mb="10px">
            وسائل الإتصال
          </Typography>
          {/*Call icon -----------*/}
          {ContactsData.map(({ name, number, img, link }, index) => {
            return (
              <ContactWidget
                key={index}
                name={name}
                number={number}
                img={img}
                link={link}
              />
            );
          })}
        </Stack>
        {/*-----------------------------------*/}

        <Box sx={{ padding: "20px 16px" }}>
          <Button
            component={NavLink}
            to="/contacts"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<MailOutlineOutlinedIcon />}
            onClick={() => {
              close();
            }}
            sx={{ ...MobileBtn }}
          >
            إرسال رسالة
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerMenu;
