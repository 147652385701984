import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";

function ContactWidget({ link, img, name, number, hiddenData }) {
  return (
    <ButtonBase href={link} target="_blank">
      <Stack direction="row" spacing="14px" alignItems="center">
        <img src={img} alt={name} width="40px" height="40px" />

        <Stack direction="column" sx={{ display: hiddenData && "none" }}>
          <Typography variant="body1" sx={{ color: "#555" }}>
            {name}
          </Typography>
          <Stack direction="row-reverse" alignItems="center" spacing="4px">
            <Typography variant="body1" sx={{ color: "#555" }}>
              (002)
            </Typography>
            <Typography variant="h5">{number}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </ButtonBase>
  );
}

export default ContactWidget;
