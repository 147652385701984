import React from "react";
import style from "./iconCard.module.css";
import { Stack, Typography, ButtonBase, Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

function IconCard({ cardType, data, clickAction }) {
  return (
    <ButtonBase onClick={clickAction} sx={{ width: "100%" }}>
      <Box
        className={style.iconCardContainer}
        sx={{ height: cardType === "mobile" ? "222px" : "195px" }}
      >
        <Stack
          direction="column"
          spacing="10px"
          sx={{ maxWidth: "110px", margin: "0px auto" }}
        >
          <LazyLoadImage
            src={data.icon}
            alt={data.name}
            effect="blur"
            className={style.iconImg}
            style={{
              boxShadow:
                cardType === "mobile" && "0px 0px 2px rgba(0, 0, 0, 16%)",
              width: cardType === "mobile" ? "83px" : "110px",
              height: cardType === "mobile" ? "83px" : "100px",
            }}
          />
          <article>
            {cardType === "mobile" && (
              <Typography variant="h5" color="initial" mt="17px" mb="3px">
                {data.name}
              </Typography>
            )}
            <Typography variant="body2" color="initial">
              {data.description.slice(0, 41)}
              {data.description.length > 41 && (
                <span style={{ paddingRight: "2px" }}>...</span>
              )}
            </Typography>
          </article>
        </Stack>
      </Box>
    </ButtonBase>
  );
}

export default IconCard;
