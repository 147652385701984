import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { theme } from "../../Theme/Theme";
import {
  Divider,
  Stack,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

import AvatarImage from "../../assets/images/avatar_img.png";
// contact Data
import { ContactsData } from "../Contacts/ContactsData";
import ContactWidget from "./ContactWidget";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ContactsModal({ open, close }) {
  const dialogStyle = {
    width: "100%",
    maxWidth: "677px", // Set your width here
    borderRadius: "10px",
    background: theme.palette.gray,
  };

  const boxStyle = {
    background: "#fff",
    maxWidth: "100%",
    padding: "27px",
    boxShadow: "0px 8px 20px rgba(0,0,0,20%)",
  };

  // Avatar Badge  ------------------------------
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  // send massage Snackbar ------------------------------
  let locationPath = document.location.href;
  const [openSnackbar, SetOpenSnackbar] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    SetOpenSnackbar(false);
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": { ...dialogStyle },
        },
      }}
    >
      <DialogContent sx={{ padding: "0px" }}>
        <Stack direction={{ xs: "column", sm: "row" }}>
          <Box sx={{ ...boxStyle }} width={{ xs: "100%", sm: "320px" }}>
            {/*Avatar -----------------*/}
            <Box sx={{ textAlign: "center" }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                variant="dot"
              >
                <LazyLoadImage
                  style={{ borderRadius: "100%" }}
                  width={88}
                  height={88}
                  alt="Sror"
                  src={AvatarImage}
                  effect="blur"
                />
              </StyledBadge>
              <Typography
                variant="body1"
                color="#333"
                sx={{ padding: "27px 0px 30px 0px" }}
              >
                تشرفت بزيارتك . متاح الان للتواصل معك عبر وسائل الإتصال التالية
              </Typography>
            </Box>
            <Typography variant="h5">الإتصال المباشر</Typography>
            <Divider sx={{ margin: "12px 0px 40px 0px" }} />

            <Stack direction="column" alignItems="flex-start" spacing="18px">
              {/*Call icon -----------*/}
              {ContactsData.map(({ name, number, img, link }, index) => {
                return (
                  <ContactWidget
                    key={index}
                    name={name}
                    number={number}
                    img={img}
                    link={link}
                  />
                );
              })}
            </Stack>

            <Typography
              variant="body2"
              color="#000"
              sx={{
                fontSize: "0.64rem",
                background: theme.palette.orange,
                textAlign: "center",
                width: "100%",
                borderRadius: "50px",
                mt: "53px",
                padding: "2px",
              }}
            >
              متاح يوميا من الساعه 09:00 ص : 09:00 م بتوقيت القاهرة
            </Typography>
          </Box>

          {/*Form Side -------------------------------------------*/}
          <Box
            sx={{ padding: "50px 32px 24px 32px" }}
            width={{ xs: "100%", sm: "357px" }}
          >
            <Box sx={{ padding: "0px 8px 0px 8px" }}>
              <Typography variant="h5">إرسال رسالة</Typography>
              <Typography variant="body1">
                سيتم التواصل معك فور الإستلام
              </Typography>
            </Box>
            <form
              action="https://formsubmit.co/srordesigner@gmail.com"
              method="POST"
              onSubmit={() => SetOpenSnackbar(true)}
            >
              <TextField
                type="hidden"
                name="_next"
                value={locationPath}
                sx={{ display: "none" }}
              />

              <TextField
                type="hidden"
                name="_template"
                value="box"
                sx={{ display: "none" }}
              />
              <TextField
                type="hidden"
                name="_captcha"
                value="false"
                sx={{ display: "none" }}
              />
              <TextField type="hidden" name="_honey" sx={{ display: "none" }} />

              <Stack direction="column" spacing="16px" mt="30px">
                <TextField
                  label="إسم الكريم"
                  required
                  variant="outlined"
                  fullWidth
                  color="info"
                  name="_subject"
                />
                <TextField
                  label="رقم واتسأب"
                  required
                  variant="outlined"
                  fullWidth
                  color="info"
                  type="tel"
                  name="whatsApp"
                />

                <TextField
                  label="الرسالة"
                  required
                  variant="outlined"
                  fullWidth
                  multiline
                  color="info"
                  rows={6}
                  name="message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt="10px"
                >
                  إرسال
                </Button>
              </Stack>
            </form>

            {/*Send success  -------------------------------------------*/}
            <Snackbar
              open={openSnackbar}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                تم إرسال الرسالة بنجاح
              </Alert>
            </Snackbar>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ContactsModal;
