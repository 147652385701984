import { Container, Box, Typography, Grid } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";

import React, { Fragment, useState } from "react";
import FrontPosterCard from "../Components/Cards/FrontPosterCard/FrontPosterCard";
import LiveDemoDialog from "../Components/LiveDemoDialog/LiveDemoDialog";
import PageDescription from "../Components/PageDescription/PageDescription";
import TotalResultsBar from "../Components/TotalResultsBar/TotalResultsBar";
import { useCollection } from "../CustomHooks/UseCollection";
import db from "../firebase/firebase";
import { motion } from "framer-motion";

function FrontEnd() {
  //  frontEnd Data from  firebase -----------------------
  const [frontEndData] = useCollection("frontEnd");
  const totalResults = frontEndData.length > 0 && frontEndData.length;
  // Cards List
  const cardsList = frontEndData.map((item) => {
    return (
      <Grid key={item.id} item xs={12} md={6}>
        <FrontPosterCard
          data={item}
          clickAction={() => LiveDemoDialogHandeler(item)}
        />
      </Grid>
    );
  });

  // Live Demo Dialog data ----------------------------------------
  const [openLiveDemoDialog, setOpenLiveDemoDialog] = useState(false);

  const [liveDemoDialogDialogData, setLiveDemoDialogData] = useState({
    id: 0,
    name: "",
    description: "",
    icon: "",
    poster: "",
    frameUrl: "",
    date: "",
    viewsCounter: 0,
    technologies: [],
  });

  // iframe Skelton
  const [displaySkelton, setDisplaySkelton] = useState(true);

  // Preview Dialog handeler -----
  const LiveDemoDialogHandeler = async (itemData) => {
    setLiveDemoDialogData(itemData);
    setOpenLiveDemoDialog(true);

    //  Update views Counter -------------------------
    const dcoRef = doc(db, "frontEnd", itemData.id);
    // update data
    await updateDoc(dcoRef, {
      viewsCounter: itemData.viewsCounter + 1,
    });

    // hide frame skeleton after 1.5 scounds
    setTimeout(() => {
      setDisplaySkelton(false);
    }, 1500);
  };

  // close Live Demo Dialog Handeler------------------------
  const closeLiveDemoDialogHandeler = () => {
    // close dialog
    setOpenLiveDemoDialog(false);
    // reset frame skeleton
    setDisplaySkelton(true);
  };
  return (
    <Fragment>
      {/*Start page description Section ---------------------*/}
      <PageDescription
        topText="اعمالى"
        pageName="تكويد واجهات المواقع"
        Description="اقدم الخدمة بواسطة مجموعه من التقنيات المختلفة التى تعتمد على نوع البرمجة المقترحة للموقع لذلك استعرض بعض اعمالى مع عرض التقنيات المستخدمة فى كل عمل  
        "
      />

      <Container fixed sx={{ mb: "40px" }}>
        {/*Total bar -----------------*/}
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <TotalResultsBar totalResults={totalResults || 0} />
        </Box>

        {/*Note bar -----------------*/}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            duration: 0.6,
            type: "spring",
            stiffness: 150,
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              background: "#FFE082",
              borderRadius: "8px",
              padding: "14px",
              textAlign: "center",
              mb: "30px",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              كافة المواقع التالية من تصميمي بالإضافة الى تكويد الواجهات وتعود
              ملكية المشاريع الى الشركات المنفذة
            </Typography>
          </Box>
        </motion.div>

        {/*Cards----------- -----------------*/}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.5,
            duration: 0.6,
            type: "spring",
            stiffness: 150,
          }}
        >
          <Grid container spacing="22px">
            {cardsList}
          </Grid>
        </motion.div>
      </Container>

      {/*-------- LiveDemoDialog --------------------*/}
      <LiveDemoDialog
        open={openLiveDemoDialog}
        close={closeLiveDemoDialogHandeler}
        data={liveDemoDialogDialogData}
        displaySkelton={displaySkelton}
      />
    </Fragment>
  );
}

export default FrontEnd;
